//General

$niceFont: "Muli", sans-serif;
$textColor: rgb(75, 75, 75);

//Responsiveness

$break-small: 625px;
$break-medium: 950px;
$break-large: 1920px;

$small: "only screen and (max-width : #{$break-small})";
$medium: "only screen and (max-width : #{$break-medium}) and (min-width : #{$break-small + 1})";
$large: "only screen and (max-width : #{$break-large}) and (min-width : #{$break-medium + 1}) ";
$huge: "only screen and (min-width : #{$break-large + 1})";

//Headings
$base-heading-size: 4em;

//Animations
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeOutExpo: cubic-bezier(0.5, 0.000, 0.000, 1.000);

@mixin responsive-width
{
    max-width: 75%;

    @media #{$medium}
    {
        max-width: 100%;
    }

    @media #{$huge}
    {
        max-width: 60%;
    }

    margin: 0 auto;
}
