@import 'variables';
@import 'mixins/reset';
@import 'mixins/headings';

@include headings()
{
    font-family: $niceFont;
}

@import 'mixins/simple-grid';
@import 'mixins/responsiveHelpers';

html, body
{
    height: 100%;
    min-width: 300px;
}

body
{
    font-family: sans-serif;
    background-color: rgb(250, 250, 245);

    &>.container
    {
        margin-top: 1em;
        margin-bottom: 5rem;
        
        &>.col-12
        {
            width: 100%;
        }

        @media #{$small}
        {
            margin-bottom: 5rem;
        }
    }

    

    section a
    {
        color: darkgrey;
    }
}

.home
{
    &, header
    {
        padding: 0;
        height: 100%;
    }

    header
    {
        font-size: 6em;

        .content
        {
            padding: 3em 0 0 0.75em;

            @media #{$huge}
            {
                padding-left: 0;
            }

            @media #{$medium}
            {
                padding-left: 0.5em;
            }

            @media #{$small}
            {
                padding-left: 0.3em;
            }
        }

        @media #{$small}
        {
            font-size: 3.5em;
        }
    }
}

header
{
    background-color: rgb(100, 100, 150);
    background-image: url(../images/header-bg.jpg);
    background-position: 0% 0%;
    background-size: cover;

    color: white;

    font-family: $niceFont;
    font-weight: bold;
    font-size: 4em;

    padding: 0.5em 0 0.5em 1em;

    text-shadow: -0px 0px 8px black, -1px 1px 4px black;
    box-shadow: inset 0 0 0px black;

    @media #{$small}
    {
        font-size: 3em;
        padding: 0.5em 0.5em 0.5em 0.5em;

        transition: background-position-y 0s $easeOutQuint;
    }

    @mixin header-light
    {
        font-size: 0.3em;
        font-weight: lighter;
    }

    & > div
    {
        @include responsive-width;
    }

    nav
    {
        margin-top: -0.33em;

        a
        {
            @include header-light;

            color: white;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0.25) 100%);
            background-size: contain;
            border-radius: 2px;

            padding: 4px 6px 4px 6px;

            text-decoration: none;

            backdrop-filter: blur(0px);
            
            transition: all 0.66s $easeOutQuint;

            &:hover
            {
                color: white;
                text-shadow: -1px 1px 2px black;

                background: rgba(0, 0, 0, 0.5);
                backdrop-filter: blur(12px);
            }

            &.current
            {
                font-weight: bold;
                background: none;

                cursor: default;
            }
        }
    }

    &.animated
    {
        transition: background-position-y 10s $easeOutQuint, opacity 1.25s $easeOutQuint;
        box-shadow: inset 0 -8px 12px -3px rgba(0,0,0,0.33);
    }
}

a
{
    transition: all 0.25s $easeOutQuint;

    &:hover
    {
        color: black;
        text-shadow: 0px 0px 8px white, 0px 0px 8px white;
    }
}

.head footer
{
    background: linear-gradient(rgba(255, 255, 255, 0) -25%, black 100%);
}

footer
{
    font-family: $niceFont;

    position: fixed;
    bottom: 0;
    padding: 1em 0 1em 1em;
    overflow: hidden;

    font-size: 0.8em;

    width: calc(100% - 1em);

    background: linear-gradient(rgba(0, 0, 0, 0.1) -25%, black 100%);
    backdrop-filter: blur(12px);

    &>div
    {
        @include responsive-width;
    }

    .right
    {
        font-size: 2em;

        text-align: right;
        margin-right: 0.8em;

        span:not(:last-child)
        {
            padding-right: 2%;
        }
    }

    .bottomOffset
    {
        display: inline-block;
        margin-top: -1.45em;

        float: left;

        &:hover {
            color: white;
            text-shadow: none;
        }
    }

    a
    {
        color: white;
        text-shadow: -1px 1px 16px rgba(0,0,0,0.5);

        text-decoration: none;
        display: inline-block;

        transition: all 0.33s $easeOutQuint;
    }
}

article
{
    @include responsive-width;
}

p
{
    color: $textColor;

    line-height: 1.5;
    font-size: 1.2em;
}
