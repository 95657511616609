// SIMPLE GRID - SASS/SCSS
// grid

@import 'variables';

$width: 96%;
$gutter: 4%;

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  @media #{$medium} {
    width: 85%;
  }

  @media #{$small} {
    width: 90%;
  }
}

.row {
  position: relative;
  width: 100%;
}

.row [class^="col"] {
  float: left;
  margin: 0.5rem 2%;
  min-height: 0.125rem;
}

.row::after {
  content: "";
  display: table;
  clear: both;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: $width + $gutter;
}

.col-1-sm { width:($width / 12) - ($gutter * 11 / 12); }
.col-2-sm { width: ($width / 6) - ($gutter * 10 / 12); }
.col-3-sm { width: ($width / 4) - ($gutter * 9 / 12); }
.col-4-sm { width: ($width / 3) - ($gutter * 8 / 12); }
.col-5-sm { width: ($width / (12 / 5)) - ($gutter * 7 / 12); }
.col-6-sm { width: ($width / 2) - ($gutter * 6 / 12); }
.col-7-sm { width: ($width / (12 / 7)) - ($gutter * 5 / 12); }
.col-8-sm { width: ($width / (12 / 8)) - ($gutter * 4 / 12); }
.col-9-sm { width: ($width / (12 / 9)) - ($gutter * 3 / 12); }
.col-10-sm { width: ($width / (12 / 10)) - ($gutter * 2 / 12); }
.col-11-sm { width: ($width / (12 / 11)) - ($gutter * 1 / 12); }
.col-12-sm { width: $width; }

@media only screen and (min-width: $break-medium) {
  .col-1 { width:($width / 12) - ($gutter * 11 / 12); }
  .col-2 { width: ($width / 6) - ($gutter * 10 / 12); }
  .col-3 { width: ($width / 4) - ($gutter * 9 / 12); }
  .col-4 { width: ($width / 3) - ($gutter * 8 / 12); }
  .col-5 { width: ($width / (12 / 5)) - ($gutter * 7 / 12); }
  .col-6 { width: ($width / 2) - ($gutter * 6 / 12); }
  .col-7 { width: ($width / (12 / 7)) - ($gutter * 5 / 12); }
  .col-8 { width: ($width / (12 / 8)) - ($gutter * 4 / 12); }
  .col-9 { width: ($width / (12 / 9)) - ($gutter * 3 / 12); }
  .col-10 { width: ($width / (12 / 10)) - ($gutter * 2 / 12); }
  .col-11 { width: ($width / (12 / 11)) - ($gutter * 1 / 12); }
  .col-12 { width: $width; }

  .hidden-sm {
    display: block;
  }
}
