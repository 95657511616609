.small, .medium, .large, .huge { display: none }

@media #{$huge}
{
    .small
    {
        display: none;
    }

    .medium
    {
        display: none;
    }

    .large
    {
        display: none;
    }

    .huge
    {
        display: unset;
    }
}

@media #{$large}
{
    .small
    {
        display: none;
    }

    .medium
    {
        display: none;
    }

    .large
    {
        display: unset;
    }

    .huge
    {
        display: none;
    }
}

@media #{$medium}
{
    .small
    {
        display: none;
    }

    .medium
    {
        display: unset;
    }

    .large
    {
        display: none;
    }

    .huge
    {
        display: none;
    }
}


@media #{$small}
{
    .small
    {
        display: unset;
    }

    .medium
    {
        display: none;
    }

    .large
    {
        display: none;
    }

    .huge
    {
        display: none;
    }
}
