@import 'variables';

@mixin headings($from: 1, $to: 6)
{
    @for $i from $from through $to
    {
        h#{$i}
        {
            font-size: #{$base-heading-size - $i};
            @content
        }
    }
}
